import { Component, OnInit, OnDestroy, AfterViewInit } from "@angular/core";
import { NavigationService } from "../../../shared/services/navigation.service";
import { ThemeService } from "../../services/theme.service";
import { BehaviorSubject, Subscription } from "rxjs";
import { ILayoutConf, LayoutService } from "app/shared/services/layout.service";
import { JwtAuthService } from "app/shared/services/auth/jwt-auth.service";
import { UserAuthorityService } from "../../../appshared/manage/user-authority/user-authority.service";
import { SessionStoreService } from "../../services/session-store.service";

interface IMenuItem {
    type: string; // Possible values: link/dropDown/icon/separator/extLink
    name?: string; // Used as display text for item and title for separator type
    state?: string; // Router state
    icon?: string; // Material icon name
    svgIcon?: string; // UI Lib icon name
    tooltip?: string; // Tooltip text
    disabled?: boolean; // If true, item will not be appeared in sidenav.
    sub?: IChildItem[]; // Dropdown items
    badges?: IBadge[];
}
interface IChildItem {
    type?: string;
    name: string; // Display text
    state?: string; // Router state
    icon?: string;  // Material icon name
    svgIcon?: string; // UI Lib icon name
    sub?: IChildItem[];
}

interface IBadge {
    color: string; // primary/accent/warn/hex color codes(#fff000)
    value: string; // Display text
}

@Component({
  selector: "app-sidebar-side",
  templateUrl: "./sidebar-side.component.html"
})
export class SidebarSideComponent implements OnInit, OnDestroy, AfterViewInit {
  public menuItems: any[];
  public hasIconTypeMenuItem: boolean;
  public iconTypeMenuTitle: string;
  private menuItemsSub: Subscription;
  public layoutConf: ILayoutConf;

  constructor(
    private navService: NavigationService,
    public themeService: ThemeService,
    private layout: LayoutService,
      public jwtAuth: JwtAuthService,
      private servUser: UserAuthorityService,
      private sessionS: SessionStoreService
  ) {}

    ngOnInit() {

        this.iconTypeMenuTitle = 'Frequently Accessed';
      //  this.fillMenu();

    this.iconTypeMenuTitle = this.navService.iconTypeMenuTitle;
    this.menuItemsSub = this.navService.menuItems$.subscribe(menuItem => {
      this.menuItems = menuItem;
      //Checks item list has any icon type.
      this.hasIconTypeMenuItem = !!this.menuItems.filter(
        item => item.type === "icon"
      ).length;
    });
    this.layoutConf = this.layout.layoutConf;
  }
  ngAfterViewInit() {}
  ngOnDestroy() {
    if (this.menuItemsSub) {
      this.menuItemsSub.unsubscribe();
    }
  }
  toggleCollapse() {
    if (
      this.layoutConf.sidebarCompactToggle
    ) {
        this.layout.publishLayoutChange({
        sidebarCompactToggle: false
      });
    } else {
        this.layout.publishLayoutChange({
            // sidebarStyle: "compact",
            sidebarCompactToggle: true
          });
    }
  }


    //iconMenu: IMenuItem[] = [];

    //public fillMenu() {
    //    var users = this.jwtAuth.getUser();
    //    this.iconMenu = [];

    //    var pageAu = this.sessionS.getItemAuth('mgUserID');

    //    this.servUser.getServiceUserMenu(+users.mgUserID, "En")
    //        .subscribe(res => {
    //            var json = JSON.parse(res);
    //            this.iconMenu = json as IMenuItem[];
    //            this.startFillMenu();
    //        })

    //}

    //menuItems2 = new BehaviorSubject<IMenuItem[]>(this.iconMenu);
    //menuItems2$ = this.menuItems2.asObservable();

    //startFillMenu() {
    //    this.menuItems2 = new BehaviorSubject<IMenuItem[]>(this.iconMenu);
    //    this.menuItems2$ = this.menuItems2.asObservable();

    //    this.menuItemsSub = this.menuItems2$.subscribe(menuItem => {
    //        this.menuItems = menuItem;
    //        //Checks item list has any icon type.
    //        this.hasIconTypeMenuItem = !!this.menuItems.filter(
    //            item => item.type === "icon"
    //        ).length;
    //    });
    //    this.layoutConf = this.layout.layoutConf;
    //}


}
